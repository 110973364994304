'use client';

import { Suspense, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/navigation';
import { AuthRequest } from '@hyex/dxp-api/authApi/types/AuthRequest';
import { signIn, useSession } from 'next-auth/react';
import Link from 'next/link';

export default function SignInForm() {
  const [loading, setLoading] = useState(false);

  const router = useRouter();

  const [myErrors, setMyErrors] = useState('');

  const { data, status, update } = useSession();

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AuthRequest>({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const handleFormSubmit = async (data: AuthRequest) => {
    setMyErrors('');

    setLoading(true);

    const response = await signIn('credentials', {
      password: data.password,
      email: data.username,
      redirect: false,
    });

    if (response?.ok === false && response.error) {
      const apiErrors = JSON.parse(response?.error);

      setMyErrors(apiErrors.message ?? '');
    }

    if (response?.ok === true && response.status === 200) {
      router.replace('/welcome');
    }

    setLoading(false);
  };

  return (
    <div className="text-page">
      <Suspense fallback={<div>Loading...</div>}>
        <h2 className="black"> Log In </h2>
        <form
          className="contact-form basic-green-gradient login-form"
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <p>
            <label>
              <span className="form-control-wrap">
                <input
                  type="email"
                  className={errors['username'] ? 'form-control error' : 'form-control'}
                  placeholder="Email Address"
                  {...register('username', {
                    required: 'This field is required',
                    pattern: {
                      value: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                      message: 'Invalid email address',
                    },
                  })}
                />
                {errors['username'] && (
                  <span className="invalid-feedback">{errors['username'].message}</span>
                )}
              </span>
            </label>
          </p>
          <p>
            <label>
              <span className="form-control-wrap">
                <input
                  type="password"
                  className={errors['password'] ? 'form-control error' : 'form-control'}
                  placeholder="Password"
                  {...register('password', {
                    required: 'This field is required',
                    minLength: {
                      value: 8,
                      message: 'Password must be at least 8 characters long',
                    },
                  })}
                />
                {errors['password'] && (
                  <span className="invalid-feedback">{errors['password'].message}</span>
                )}
              </span>
            </label>
          </p>

          {myErrors && <p className="custom-errors">{myErrors}</p>}

          <button
            type="submit"
            className="submit-button form-control submit colored"
            disabled={loading}
          >
            {loading ? 'Sending, please wait...' : 'Login'}
          </button>
        </form>
        <p style={{ textAlign: 'center' }} className="aux-auth-text">
          <Link href={'/forgot-password'}>
            <b>Forgot password?</b>
          </Link>
        </p>
        {/* <p style={{ textAlign: 'center' }} className="aux-auth-text">
          <Link href={'/sign-up'}>
            <u>Or Sign Up</u>
          </Link>
        </p> */}
      </Suspense>
    </div>
  );
}
