'use client';

import { signOut } from 'next-auth/react';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import { FC, use, useEffect } from 'react';
import { Spinner } from '@nextui-org/spinner';

const UserProfile: FC = () => {
  const { data: session, status } = useSession();

  if (status === 'loading')
    return (
      <div className="text-page">
        <h2>Loading...</h2>
        <Spinner />
      </div>
    );

  return (
    <div className="text-page">
      {session && (
        <>
          <h2>Authorized Dashboard (User Profile) {session.user?.email}</h2>
          <button
            onClick={() => signOut()}
            className="bg-red-500 text-white font-bold px-6 py-2 mt-3"
          >
            Log Out
          </button>
        </>
      )}
    </div>
  );
};

export default UserProfile;
